//
//  SETTINGS
//

$js-cat-nav-background-color: $primary-color;
$js-cat-nav-padding: 0;

// SPACING BETWEEN LINKS
$js-cat-nav-item-spacing: 1px;

// LV1 (ALWAYS VISIBLE)
$js-cat-nav-lv1-item-margin: 0;
$js-cat-nav-lv1-item-padding: 0;
$js-cat-nav-lv1-item-width: auto;
$js-cat-nav-lv1-link-height: 40px;
$js-cat-nav-lv1-link-padding: 0px 14px 0;
$js-cat-nav-lv1-link-background-color: $js-cat-nav-background-color;
$js-cat-nav-lv1-link-hover-background-color: darken($js-cat-nav-lv1-link-background-color,10%);
$js-cat-nav-lv1-link-active-background-color: darken($js-cat-nav-lv1-link-background-color,10%);
$js-cat-nav-lv1-link-active-hover-background-color: darken($js-cat-nav-lv1-link-background-color,15%);
$js-cat-nav-lv1-link-font-weight: bold;
$js-cat-nav-lv1-link-font-family: $font-family-header;
$js-cat-nav-lv1-link-font-size: 15px;
$js-cat-nav-lv1-link-text-decoration: none;
$js-cat-nav-lv1-link-hover-text-decoration: none;
$js-cat-nav-lv1-link-color: #fff;
$js-cat-nav-lv1-link-hover-color: #fff;
$js-cat-nav-lv1-link-active-color: #fff;
$js-cat-nav-lv1-link-active-hover-color: #fff;
$js-cat-nav-lv1-link-side-border: 1px solid #fff;

// MEGADROPDOWN DEFINITIONS

// LV2
$js-cat-nav-lv2-container-border: 1px solid $js-cat-nav-lv1-link-hover-background-color;
$js-cat-nav-lv2-container-padding: 15px 15px 5px 15px;
$js-cat-nav-lv2-container-background-color: rgba(255,255,255,1);
$js-cat-nav-lv2-container-shadow: 0 2px 3px rgba(0,0,0,0.5);
$js-cat-nav-lv2-item-margin: 0 1% 15px 0;
$js-cat-nav-lv2-item-padding: 0;
$js-cat-nav-lv2-item-width: 19%;
$js-cat-nav-lv2-link-padding: 4px 5px;
$js-cat-nav-lv2-link-margin: 0;
$js-cat-nav-lv2-link-background-color: #eee;
$js-cat-nav-lv2-link-hover-background-color: darken($js-cat-nav-lv2-link-background-color, 10%);
$js-cat-nav-lv2-link-active-background-color: #c5c5c5;
$js-cat-nav-lv2-link-active-hover-background-color: darken($js-cat-nav-lv2-link-active-background-color,5%);
$js-cat-nav-lv2-link-selected-background-color: darken($js-cat-nav-lv2-link-active-background-color,5%);
$js-cat-nav-lv2-link-selected-hover-background-color: #c5c5c5;
$js-cat-nav-lv2-link-font-weight: bold;
$js-cat-nav-lv2-link-active-font-weight: $js-cat-nav-lv2-link-font-weight;
$js-cat-nav-lv2-link-selected-font-weight: $js-cat-nav-lv2-link-font-weight;
$js-cat-nav-lv2-link-font-family: $js-cat-nav-lv1-link-font-family;
$js-cat-nav-lv2-link-font-size: 13px;
$js-cat-nav-lv2-link-line-height: 1.3;
$js-cat-nav-lv2-link-text-decoration: none;
$js-cat-nav-lv2-link-hover-text-decoration: none;
$js-cat-nav-lv2-link-color: #000;
$js-cat-nav-lv2-link-hover-color: $js-cat-nav-lv2-link-color;
$js-cat-nav-lv2-link-active-color: $js-cat-nav-lv2-link-color;
$js-cat-nav-lv2-link-active-hover-color: $js-cat-nav-lv2-link-hover-color;
$js-cat-nav-lv2-link-selected-color: #000;
$js-cat-nav-lv2-link-selected-hover-color: $js-cat-nav-lv2-link-selected-color;

// LV3
$js-cat-nav-lv3-container-border: none;
$js-cat-nav-lv3-container-padding: none;
$js-cat-nav-lv3-container-background-color: transparent;
$js-cat-nav-lv3-container-shadow: none;
$js-cat-nav-lv3-item-margin: 2px 0 5px 5px;
$js-cat-nav-lv3-item-padding: 0;
$js-cat-nav-lv3-item-width: auto;
$js-cat-nav-lv3-link-padding:  0;
$js-cat-nav-lv3-link-margin: 0px;
$js-cat-nav-lv3-link-background-color: transparent;
$js-cat-nav-lv3-link-hover-background-color: darken($js-cat-nav-lv3-link-background-color, 10%);
$js-cat-nav-lv3-link-active-background-color: darken($js-cat-nav-lv3-link-background-color, 15%);
$js-cat-nav-lv3-link-active-hover-background-color: darken($js-cat-nav-lv3-link-active-background-color, 10%);
$js-cat-nav-lv3-link-selected-background-color: transparent;
$js-cat-nav-lv3-link-selected-hover-background-color: $js-cat-nav-lv1-link-active-background-color;
$js-cat-nav-lv3-link-font-weight: normal;
$js-cat-nav-lv3-link-active-font-weight: $js-cat-nav-lv3-link-font-weight;
$js-cat-nav-lv3-link-selected-font-weight: bold;
$js-cat-nav-lv3-link-font-family: $js-cat-nav-lv2-link-font-family;
$js-cat-nav-lv3-link-font-size:  $js-cat-nav-lv2-link-font-size;
$js-cat-nav-lv3-link-line-height: 1.3;
$js-cat-nav-lv3-link-text-decoration: none;
$js-cat-nav-lv3-link-hover-text-decoration: underline;
$js-cat-nav-lv3-link-color: #000;
$js-cat-nav-lv3-link-hover-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv3-link-active-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv3-link-active-hover-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv3-link-selected-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv3-link-selected-hover-color: $js-cat-nav-lv2-link-selected-color;

// LV4
$js-cat-nav-lv4-container-border: none;
$js-cat-nav-lv4-container-padding: none;
$js-cat-nav-lv4-container-background-color: transparent;
$js-cat-nav-lv4-item-padding: 0;
$js-cat-nav-lv4-item-margin: 1px 0 0 10px;
$js-cat-nav-lv4-item-width: auto;
$js-cat-nav-lv4-link-padding: 0;
$js-cat-nav-lv4-link-margin: 0;
$js-cat-nav-lv4-link-background-color: lighten($js-cat-nav-lv3-link-background-color, 10%);
$js-cat-nav-lv4-link-hover-background-color: darken($js-cat-nav-lv4-link-background-color, 10%);
$js-cat-nav-lv4-link-active-background-color: darken($js-cat-nav-lv4-link-background-color, 15%);
$js-cat-nav-lv4-link-active-hover-background-color: darken($js-cat-nav-lv4-link-active-background-color, 10%);
$js-cat-nav-lv4-link-selected-background-color: $js-cat-nav-lv1-link-active-background-color;
$js-cat-nav-lv4-link-selected-hover-background-color: $js-cat-nav-lv1-link-active-background-color;
$js-cat-nav-lv4-link-font-weight: $js-cat-nav-lv3-link-font-weight;
$js-cat-nav-lv4-link-active-font-weight: $js-cat-nav-lv3-link-font-weight;
$js-cat-nav-lv4-link-selected-font-weight: bold;
$js-cat-nav-lv4-link-font-family: $js-cat-nav-lv3-link-font-family;
$js-cat-nav-lv4-link-font-size: $js-cat-nav-lv3-link-font-size * 0.9;
$js-cat-nav-lv4-link-line-height: $js-cat-nav-lv3-link-line-height;
$js-cat-nav-lv4-link-text-decoration: none;
$js-cat-nav-lv4-link-hover-text-decoration: underline;
$js-cat-nav-lv4-link-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv4-link-hover-color: $js-cat-nav-lv3-link-hover-color;
$js-cat-nav-lv4-link-active-color: $js-cat-nav-lv3-link-active-color;
$js-cat-nav-lv4-link-active-hover-color: $js-cat-nav-lv3-link-active-hover-color;
$js-cat-nav-lv4-link-selected-color: $js-cat-nav-lv3-link-selected-color;
$js-cat-nav-lv4-link-selected-hover-color: $js-cat-nav-lv3-link-selected-hover-color;

// SEE ALL
$js-cat-nav-see-all-item-margin: 2px 0 0 5px;
$js-cat-nav-see-all-item-padding: 0;
$js-cat-nav-see-all-link-margin: 0px 0;
$js-cat-nav-see-all-link-padding: 0;
$js-cat-nav-see-all-link-background-color:  transparent;
$js-cat-nav-see-all-link-hover-background-color:  transparent;
$js-cat-nav-see-all-link-color: $js-primary-link-color;
$js-cat-nav-see-all-link-hover-color: $js-primary-link-hover-color;
$js-cat-nav-see-all-link-font-size: $js-cat-nav-lv3-link-font-size;
$js-cat-nav-see-all-link-text-decoration: none;
$js-cat-nav-see-all-link-hover-text-decoration: underline;
$js-cat-nav-see-all-indicator-content: "\f054 ";
$js-cat-nav-see-all-indicator-font-size: 10px;
$js-cat-nav-see-all-indicator-margin: -2px 4px 0 0;
