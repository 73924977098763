//
//  Releware
//

@import "../../../scss/foundation/functions";
@import "../../../scss/base_settings";
@import "../../../scss/responsive-base/mixins";

.releware-wrapper {
  clear: both;
  overflow: hidden;
}

.releware-wrapper h2 {
  clear: both;
}

.releware-wrapper a.product-image {
  display: inline-block;
}

.releware-wrapper .product-name {
  height: 40px;
  overflow: hidden;
}

.RecommendationGeneralWrapper .NewsListItemImage a:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.releware-wrapper .product-wrapper {
  overflow: hidden;
}

// RECOMMENDATION GENERAL LIST BOX
.RecommendationGeneralWrapper .NewsListHeader {
  display: block;
}

.RecommendationGeneralWrapper .NewsListHeader h2 {
  display: block;
}

.RecommendationGeneralWrapper .NewsListBody {
  padding-left: 10px;
  padding-right: 10px;
}

.RecommendationGeneralWrapper .NewsListItem {
  clear: both;
}

.RecommendationGeneralWrapper .NewsListItemImage {
  float: left;
  text-align: center;
  padding-right: 5px;
}

.RecommendationGeneralWrapper .NewsListItemImage a {
  display: block;
  overflow: hidden;
}

.RecommendationGeneralWrapper .NewsListItemImage a img {
  max-width: 100% !important;
}

.browser-firefox .RecommendationGeneralWrapper .NewsListItemImage a img {
  max-width: 100px !important;
}

.RecommendationGeneralWrapper .NewsListItemText {
}

.RecommendationGeneralWrapper .NewsListItemText a {
  font-size: 14px;
  font-weight: 700;
}

.RecommendationGeneralWrapper .NewsListItemFooter {
  clear: both;
}

.RecommendationGeneralWrapper .NewsListItemSeparator {
  clear: both;
  margin: 5px 0;
}

.RecommendationGeneralWrapper .RWItemTemplate {
  //clear: both;
  margin-top: 10px;
}

//
//  "New" template for Recommendation
//

//  Override image height for Releware items:
.releware-recommendation-wrapper .product-outer-wrapper .product-image {
  padding-top: $js-releware-wrapper-image-wrapper-height;
}


.releware-recommendation-wrapper {
  margin-bottom: 24px;
  width: 100%;
  float: left;
  margin-top: 20px;
  .msie .RWThumbnail {
    width: 100%;
  }
  ul {
    li {
      .product-wrapper {
        .product-buttons,
        .product-buttons:hover {
          margin: 0 0 5px 0;
          overflow: hidden;
          a {
            width: 100%;
          }
        }
      }
    }
  }
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(2, $js-product-wrapper-margin-right, $js-releware-wrapper-selector);
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(2, $js-product-wrapper-margin-right, $js-releware-wrapper-selector);
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, $js-releware-wrapper-selector);
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, $js-releware-wrapper-selector);
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, $js-releware-wrapper-selector);
}



