//
// DYNAMIC CART
//
@import "dynamic-cart-settings";


#dc-wrapper {
  color: $js-body-font-color;
  line-height: 1.2;
  #dc-inner {
    @include clearfix();
  }
  h3 {
  }
  #dc-loader-msg {
    max-height: 0;
    padding: 0;
    transition: 0.3s;
    overflow: hidden;
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    &::before {
      content: url("../images/ajax-loader-big.gif");
      display: inline-block;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 10px;
    }
  }

  &.loading #dc-loader-msg {
    max-height: 4em;
    padding: 10px;
  }
  #dc-body {
  }

  #dc-items-header {
    > div {
      background: #ddd;
      color: #666;
      font-weight: bold;
      min-height: 2em;
      float: left;
      padding: 6px;
    }
    .price {
      text-align: center;
    }
    .total {
      text-align: right;
      padding-right: 10px;
    }

  }

  #dc-content {
    > div {
      @include clearfix();
      transition: opacity 0.3s;
      clear: both;
      > div {
        float: left;
        padding: 5px;
      }
    }
    .dc-item-row {
      border-bottom: 1px solid #ddd;
      @keyframes animatedBackgroundCart {
        from {
          background-color: lighten($primary-color, 35%);
        }
        to {
          background-color: #fff
        }
      }
      &.product-added {
        animation: animatedBackgroundCart 0.8s linear 1;
      }
      &.deleting {
        opacity: 0;
      }
    }
    .img {
      padding-left: 0;
      padding-right: 0;
      background-color: white;
      a {
        overflow: hidden;
        display: block;
        position: relative;
        padding-top: $js-product-wrapper-image-wrapper-height;
        img {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          max-height: 100%;
          max-width: 100%;
          margin: auto;
        }
      }
    }

    .item {
      padding-left: 10px !important;
      .description {
        .name {
        }
        .item-number {
          display: block;
          font-size: 0.8rem;
          padding: 3px 0 0;
        }
        .comments {
          font-style: italic;
        }
      }
    }
    .price {
      text-align: center;
      white-space: nowrap;
    }
    .qty {
      text-align: center;

      .label {
        display: none;
      }
    }
    .delete {
      i {
        opacity: 0.3;
        font-size: 18px;
        cursor: pointer;
        line-height: 1;

        &:hover {
          opacity: 1;
        }
      }
    }
    .total {
      text-align: right;
      white-space: nowrap;
      padding-right: 10px;
    }
  }

  #dc-totals {
    text-align: right;
    padding: 15px 10px 0 0;
    span {
      display: inline-block;
      font-size: 1.2em;
      font-weight: bold;
    }
    .value {
      transition: 0.3s;
      min-width: 100px;
    }
    .vat-total {
      span {
        font-size: 1em;
        color: #666;
        font-weight: normal;
      }
      .value {
        padding-right: 3px;
      }
    }
  }

  &.deleting #dc-totals .value {
    opacity: 0;
  }

  #dc-btns {
    padding: 15px 0 0;
    .button {
      float: right;
      margin: 0 0 0 10px;
      &#dc-checkout-btn {
        cursor: pointer;
        background: $js-button-buy-background-color;
        &:hover {
          background: darken($js-button-buy-background-color, $js-checkout-form-button-factor);
        }
        &.disabled {
          cursor: not-allowed;
          background-color: $js-button-disabled-background-color;
        }
      }
    }
  }
}

// MODAL SPECIALS
.dynamic-cart-modal {
  header #dc-wrapper {
    display: none !important;
  }
  #dc-wrapper {
    background: #fff;
    color: $js-body-font-color;
    line-height: 1.2;
    border: $js-dc-modal-wrapper-border-width solid $js-dc-modal-wrapper-border-color;
    display: none;
    width: $js-dc-modal-width-large-up;
    padding: $js-dc-modal-wrapper-padding;
    visibility: visible !important;
    #dc-inner {

    }
    .img {
      width: $js-dc-modal-field-width-image;
    }
    .item {
      width: ($js-dc-modal-width-large-up -
          $js-dc-modal-field-width-image - 
          $js-dc-modal-field-width-price - 
          $js-dc-modal-field-width-qty - 
          $js-dc-modal-field-width-delete - 
          $js-dc-modal-field-width-total - 
          ($js-dc-modal-wrapper-padding * 2) - 
          ($js-dc-modal-wrapper-border-width * 2)
      );
    }
    .price {
      width: $js-dc-modal-field-width-price;
      .separator {
        display: none;
      }
      .qty-suffix {
        display: none;
      }
    }
    .qty {
      width: $js-dc-modal-field-width-qty;
    }
    .delete {
      width: $js-dc-modal-field-width-delete;
    }
    .total {
      width: $js-dc-modal-field-width-total;
    }
  }
  #dc-wrapper.reveal-modal {
  }
}

// DROPDOWN SPECIALS (ONLY ACTIVE FOR MEDIUM-UP)

//
//  Medium -up
//
@media #{$medium-up} {

  .dynamic-cart-dropdown {
    #dc-wrapper {
      position: relative;
      font-size: 0.9em;
      opacity: 0;
      transition: opacity 0.3s;
      max-height: 0;
      z-index: 20;
      // NEGATE REVEAL MODAL STYLING
      border-radius: 0;
      display: block !important;
      opacity: 1 !important;
      top: initial;
      visibility: visible !important;
      width: auto;
      left: initial;
      background-color: transparent;
      padding: 0;
      border: none;
      box-shadow: none;
      margin: 0;
      max-width: none;
      right: initial;
      #dc-header, #dc-items-header, .dc-item-row, #dc-totals, #dc-btns {
        //max-height: 0;
        transition: 0.3s;
        overflow: hidden;
      }
      &.dropdown-open {
        max-height: none;
        z-index: 20;
        opacity: 1;
        #dc-header, #dc-items-header, .dc-item-row, #dc-totals, #dc-btns {
          max-height: 80px;
          transition: 0.3s;
        }

        #dc-inner {
          //padding-top: 0;
          //padding-bottom: 0;
        }
      }
      #dc-inner {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;
        color: $js-body-font-color;
        line-height: 1.2;
        border: 1px solid #000;
        box-sizing: padding-box;
        z-index: 20;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        width: $js-dc-dropdown-width-large-up;
        padding: $js-dc-dropdown-wrapper-padding;
      }
      h3 {
        font-size: 1.2em;
        margin: 0 0 0.5em;
      }
      .img {
        width: $js-dc-dropdown-field-width-image;
      }
      .item {
        width: ($js-dc-dropdown-width-large-up - $js-dc-dropdown-field-width-image - $js-dc-dropdown-field-width-price - $js-dc-dropdown-field-width-qty - $js-dc-dropdown-field-width-delete - $js-dc-dropdown-field-width-total - ($js-dc-dropdown-wrapper-padding * 2) - 2);
      }
      .price {
        width: $js-dc-dropdown-field-width-price;
      }
      .qty {
        width: $js-dc-dropdown-field-width-qty;
      }
      .delete {
        width: $js-dc-dropdown-field-width-delete;
      }
      .total {
        width: $js-dc-dropdown-field-width-total;
      }
    }
  }
}

//
//  Medium-down
//
@media #{$medium-down} {
}

//
//  Medium
//
@media #{$medium-only} {

  // MODAL
  .dynamic-cart-modal {

    #dc-wrapper {
      width: $js-dc-modal-width-medium;

      .item {
        width: ($js-dc-modal-width-medium - 
          $js-dc-modal-field-width-image - 
          $js-dc-modal-field-width-price - 
          $js-dc-modal-field-width-qty - 
          $js-dc-modal-field-width-delete - 
          $js-dc-modal-field-width-total -
          ($js-dc-modal-wrapper-padding * 2) - 2);
      }

    }
  }

  // DROPDOWN
  .dynamic-cart-dropdown {
    #dc-wrapper {
      #dc-inner {
        width: $js-dc-dropdown-width-medium;
      }

      .item {
        width: ($js-dc-dropdown-width-medium - $js-dc-dropdown-field-width-image - $js-dc-dropdown-field-width-price - $js-dc-dropdown-field-width-qty - $js-dc-dropdown-field-width-delete - $js-dc-dropdown-field-width-total - ($js-dc-dropdown-wrapper-padding * 2) -2);
      }
    }
  }
}

//
//  Small
//
@media #{$small-only} {
  #dc-inner {
    display: block !important;
  }
  #dc-wrapper.reveal-modal {
    min-height: 0;
    width: 100%;
    min-width: 300px;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 10px 15px 15px;
    &.open {
      visibility: visible;
    }
    #dc-items-header {
      display: none;
    }
    #dc-content {
      display: block;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      .dc-item-row {
        display: block;
        padding: 10px 0 10px 0;
        position: relative;
        border-bottom: none;
        > * {
          float: none;
          padding: 0;
          text-align: left;
          border: none;
          width: auto;
        }
        + .dc-item-row {
          border-top: 1px dashed #ccc;
        }
      }
      .img {
        display: none;
        a {
          img {
          }
        }
      }
      .item {
        padding-left: 0px !important;
        padding-bottom: 10px;
        .description {
          padding-right: 30px;
          .name {
            font-weight: bold;
          }
          .item-number {
            display: none;
          }
        }
      }
      .price {
        text-align: left;
        display: inline-block;
        padding: 0 10px 0 0;
      }
      .qty {
        text-align: center;
        display: inline-block;

        .label {
          display: inline-block;
          font-size: inherit;
        }
      }
      .delete {
        position: absolute;
        top: 7px;
        right: 5px;
        i {
          font-size: 24px;
          opacity: 0.3;

          &:hover {
            opacity: 1;
          }
        }
      }
      .total {
        float: right;
        font-weight: bold;
      }
    }
    #dc-totals {
      text-align: right;
      padding-right: 5px;
    }
    #dc-btns {
      .button {
        //float: none;
        display: inline-block;
        margin: 0;
        margin-bottom: 4px;
        padding: 6px 10px;
        &#dc-checkout-btn {
          //background: $js-button-buy-background-color;
          margin-left: 4px;
          &:hover {
            background:darken($js-button-buy-background-color, $js-checkout-form-button-factor);
          }
        }
      }
    }
  }
}
