section.category-page-wrapper {
	&.dynamic-filter-products {
		.paging-control {
			display: none;
		}

		ul.product-list {
			display: block;
		}
	}
}