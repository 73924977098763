#custom-tab-button-1 {
  display: none !important;
}

#product-custom-tabs {
  border: 1px solid #efefef;
  background: #f4f4f4;
  font-size: 0;
  margin-bottom: 2rem;
  margin: 0 auto;
  max-width: 1160px;
  float: left;
  width: 100%;
  @media #{$small-only} {
    margin-top: 40px;
  }

  .custom-tabs-toggle-wrapper {
    background: $accent;
  }
  button.custom-tabs-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: .85rem;
    display: inline-block;
    padding: 1rem 2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 0;
    background: $accent;
    border: 0;
    border-right: 1px solid #fff;
    color: #fff;
    @media #{$small-only} {
      padding: 1rem 0.5rem;
    }
    &.active {
      background: #fff;
      color: $primary-color;
      font-weight: bold;
    }
    &:focus {
      outline: none;
    }
  
    @media #{$medium-down} {
      width: 49.9%;
      font-size: .75rem;
      &:nth-of-type(2n) {
        border-right: 0;
      }
    }
  }

  .custom-tabs-content {
    display: none;
    width: 100%;
    background: #fff;
    padding: 2rem;
    font-size: 14px;
    line-height: 24px;
    @include clearfix();
    &.active {
      display: block;
    }

    iframe {
      @media #{$small-only} {
        width: 100% !important;
        height: 100% !important;
      }
    }

    h1,h2,h3,h4 {
      letter-spacing: .5px;
      margin-top: 1rem;
      margin-bottom: 0;
    }
    ol {
      margin-top: 0;
    }
  }

}

#tab-custom-specification {
  .skotselrad {
    //display: none;
  }
  .templateRow.ejretur {
    display: none;
  }
}

//
//  Medium
//
@media #{$medium-only} {
}

//
//  Small
//
@media #{$small-only} {

}

#product-custom-tabs #custom-tab-button-1 {
  display: none!important;
}