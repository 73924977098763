
/* PRODUCT PAGE
----------------------------------------------------------------------------- */

#stock-notification-popup {
  height: auto;

  * {
    box-sizing: border-box;
  }
}

.page-showproduct {
  .fancybox-iframe {
    html, body {
      height: 100%;
    }
  }
  #content-wrapper{
    margin-top: 30px;
  }
  #left-area {
    margin-top: 10px;
  }
}


.product-images {
  float: left;
  width: 60%;
  margin: 0 0 10px 0;
  padding-right: 15px;
}

.product-description {
  float: right;
  width: 40%;
  margin: 0 0 10px 0;
  clear: right;
  border: 1px solid #efefef;
  padding: 40px 20px;
}

//PACKAGE AND UPSELL STUF.

.package-active, .upsell-active {
  .product-images {
    width: 40%;
  }
  .product-description {
    width: 60%;
  }

  .description {
    max-height: 130px;
  }
}


#FrontImage {
  max-width: 100%;
  cursor: pointer;
  vertical-align: bottom;
  text-align: center;
  margin: auto !important;
}


.show-product-image {
  position: relative;
  @media #{$small-only} {
    min-height: 100%
  }
}

a.ProductThumbnail {
//  overflow: hidden;
//  width: 20%;
//  float: left;
}

a.ProductThumbnail img {
//  margin: 10px;
}

.ProductThumbnailsBody {
  margin-top: 20px;
  ul {
    text-align: center;
    li {
      float: none;
      display: inline-block;
    }
  }
}
.ProductImageClickMessage {
  display: none;
}

h1.product-page-header {
  margin: 0 0 10px 0;
  font-size: 1.5rem;
}

h2.product-subname {
  margin: 0 0 10px 0;
  color: $js-product-list-subname-color;
  font-size: 1rem;
}

h1.product-page-header,
h2.product-subname {
  word-wrap: break-word;
  width: 100% !important;
  font-weight: normal;
}

.product-short-description {
  @include js-description-text($js-description-font-size);
}

.tab-item.tab-description {
  @include js-description-text($js-description-font-size);
  //border-bottom: 1px solid #dfdfdf;
}

.tab-item-inner {
  .description-toggle {
    display: none;
  } 
  &.active {
    .description-toggle {
      display: block;
      margin-top: 20px;
      span {
        color: $primary-color;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}

.description {
  max-height: 100px;
  overflow: hidden;
  &.active{
    max-height: 100%;
  }
}

.description-toggle .more {
  font-family: $font-family-sans-serif;
  display: block;
  &:hover {
    cursor: pointer;
  }
  &.inactive {
    display: none;
  }
}

.description-toggle .less {
  font-family: $font-family-sans-serif;
  display: none;
  &:hover {
    cursor: pointer;
  }
  &.active {
    display: block;
  }
}


.product-short-description, .product-description .inner {
  margin: 0 0 5px 0;
}

.product-short-description {
  margin-bottom: 22px;
  font-family: $font-family-sans-serif;
}

.product-description {
  text-align: center;
}

.product-description .inner div {
  margin: 0 0 5px 0;
}

span.product-article-number-label, span.product-unit-label, span.stock-status-label {
  font-weight: bold;
  margin: 0 5px 0 0;
}

.purchase-block-stock {
  margin-top: 10px;
  span {
    font-weight: bold;
  }
}

.Gilla {
}

// Hide article number
.product-article-number {
}

// Hide product unit
.product-unit {
  display: none;
}

.ProductCommentItemCssClass {
  margin: 0 0 20px 0;
}

.ProductCommentItemCssClass span {
  display: block;
  font-weight: bold;
}

.ProductCommentLargeInputCssClass {
  width: 100%;
  padding: 4px;
  border: 1px solid #cdcdcd;
  resize: vertical;
}


.product-specification-params {
  display: none;
}

.product-attributes {
  overflow: hidden;
}



.product-attributes-1 {
  margin: 0 20px 0 0;
  font-weight: bold;
}

.product-attributes-2 {
  margin: 12px 0 10px 0;
  font-weight: bold;
}

.product-attributes span {
  display: block;
  margin: 0 0 3px 0;
}


span.product-specification-label {
  font-weight: bold;
  display: block;
  margin: 0 0 2px 0;
}

.product-specification-wrapper {
  margin-bottom: 24px;
}

.product-specification-wrapper select {
  min-width: 190px;
  margin: 0 0 10px 0;
}

.purchase-block {
  clear: both;
}

.page-showproduct .purchase-block-price {
  margin-bottom: 10px;
}

.page-showproduct .purchase-block-price-currency {
  padding: 0 0 0 5px;
}

.page-showproduct .purchase-block-price-unit {
  display: none;
}

.purchase-block-price .KlarnaMonthlyCost {
  margin: 5px 0 0 0;
}

.purchase-block-quantity {
  display: inline-block;
  margin: 0 10px 0 0;
}

.purchase-block-buy {
  width: auto;
  display: inline-block;
}

.purchase-block-quantity span {
  display: none;
}

.purchase-block-quantity input {
  width: 36px;
  height: 35px;
  padding: 0;
  text-align: center;
}



/* TEMPLATES */
.template-wrapper .templateRow,
.template-wrapper .templateAlternateRow
{
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 10px 10px 0;
  &:last-child {
    border-bottom: 0;
  }
}


.templateItemTitle {
  display: inline-block;
  width: 150px;
  font-weight: bold;
  vertical-align: top;
}

.templateItemData {
  display: inline-block;
  width: 150px;
  vertical-align: top;
}

.tab-specification {
  .tab-item-inner {
    
  }
}

.tab-item-header{
  &:hover {
    cursor: pointer;
  }
  &.active {
    &:after {
      content: "\f068";
      display: inline-block;
      float: right;
      padding-right: 10px;
      padding-top: 7px;
      font-family: FontAwesome;
      font-size: 12px;
    }
  }
  &:after {
    // content: "\f067";
    // display: inline-block;
    // float: right;
    // padding-right: 10px;
    // padding-top: 7px;
    // font-family: FontAwesome;
    // font-size: 12px;
  }
}

/* RELATED PRODUCTS */
.tab-related {
  //border-bottom: 1px solid #dfdfdf;
}
table.relatedTable tr {
  border-bottom: 1px solid #dfdfdf;
}

table.relatedTable tr:first-child, table.relatedTable tr:last-child {
  border-bottom: 0;
}

table.relatedTable tr.headerGray {
  display: none;
}

table.relatedTable td {
  vertical-align: middle;
  padding: 10px 10px 10px 0;
  @media #{$small-only} {
    padding: 5px 4px 10px 0;
  }
}

td.RelatedProductImage {
  width: 30%;
  text-align: left !important;
}

td.RelatedProductName {
  width: 30%;
}

td.RelatedProductName a {
  font-weight: bold;
}

td.RelatedProductArticleNumber {
  display: none;
}

td.RelatedProductPrice {
  width: 20%;
}

td.RelatedProductButtons {
  width: 20%;
}

td.RelatedProductButtons a {
  float: right;
  margin-right: 5px;
  @media #{$small-only} {
    width: 100%;
  }
}

td.RelatedProductButtons br {
  display: none;
}

/* TOOLBAR */
.product-toolbar {
  @include clearfix;
  clear: both;
  display: inline-block;
  margin: 0 10px 10px 0;
  //padding: 10px;
  //background: #efefef;
  //border-top: 1px solid #cdcdcd;
  //behavior: url(/SystemScripts/PIE.htc);
}

.product-toolbar img {
  width: 75%;
}

.product-toolbar-image-wrapper {
  float: left;
  margin: 0 15px 0 0;
  cursor: pointer;
}

.product-toolbar-image-wrapper.product-toolbar-print,
.product-toolbar-image-wrapper.product-toolbar-showimage,
.product-toolbar-image-wrapper.product-toolbar-pdf
{
  display: none;
}

.social-media {
  float: right;
}

.product-page-back-link {
  clear: both;
  float: left;
  cursor: pointer;
  margin: 10px;
  font-weight: bold;
  display: none;
}

/* PRODUCT FILES */
.product-files-box {
  background: white;
  border: 1px solid #D8D8D8;
  padding: 10px;
  text-align: left;
  width: 300px;
  min-width: 300px;
  border: solid 1px rgb(180, 180, 180);
}

.product-files-box-shadow {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  opacity: 0.15;
  filter: alpha(opacity=15);
}

.product-files-link {
  line-height: 21px;
  padding-left: 5px;
}

.product-files-button {
  float: left;
}

.product-files-box-close {
  min-width: 30px;
  min-height: 30px;
  text-decoration: underline;
  cursor: pointer;
  background: url(/images/fancybox/fancy_closebox.png) no-repeat;
  height: 30px;
  width: 30px;
  float: right;
}

.product-files-header {
  font-weight: bold;
}

#tab-wrapper {
  display: none !important;
}

div.product-tabs {
  margin: 0;
}

//
//  Tab system
//

#tab-wrapper {
  clear: both;
}

.tab-item {
  margin-bottom: 5px;
  //border-bottom: 1px solid #dfdfdf;
  &:first-child {
    margin-top: 44px;
  }
  .tab-item-header {
    // font-size: 1.4em;
    // padding-bottom: 3px;
    // border-bottom: 1px solid #efefef;
    // background: $accent;
    // padding: 10px;
    // font-weight: normal;
    // color: #fff;
  }
  .tab-item-inner {
    padding: 20px;
  }
}


//
//  Errors on product comment validation
//
.ProductCommentValidatorErrorCssClass {
  color: $alert-color;
  background-color: lighten($alert-color, 50%);
  padding: 5px;
  &:before {
    font-family: "FontAwesome";
    content: "\f071";
    padding-right: 6px;
  }
}


.read-more {
  font-weight: bold;
}

//
//  Small
//
@media #{$small-only} {

  .product-images {
    float: none;
    width: 100%;
    padding-right: 0;
  }

  .product-description {
    float: none;
    width: 100%;
    clear: both;
  }

  .package-active, .upsell-active {
    .product-images {
      width: 100%;
    }
    .product-description {
      width: 100%;
    }

    .description {
      max-height: 130px;
    }
  }


}

// ZOOM

#zoomed-image {
  transform: scale(2) translateX(40%) translateY(40%);
  min-width: 340px;
}