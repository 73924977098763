@import "../../../scss/foundation/functions";
@import "../../../scss/responsive-base/mixins";
@import "../../../scss/base_settings";

@import "base_nouislider";
@import "base_sumoselect";
@import "base_spinner";
@import "dynamic-filter-gui-top-products";

html.page-listproductswithimageadvanced.testing,
html.page-listproducts.testing {
  div.filter-control-body {
    display: none;
  }
}

html.testing {

div.filter-choices {
  background: #fff;
  border-bottom: 1px solid #e3e3e3;
  padding: 0;
  .filter-choices-inner {
    max-width: 1340px;
    padding: 1rem 0px;
    margin: auto;
    @include clearfix();
  }
}

div.filter-container {
  background: #fff;
  .filter-options {
    max-width: 1340px;
    padding: 0;
    margin: auto;
    @media #{$medium-down} {
      padding: 0;
    }

    > h2 {
      float: left;
      margin: 0 1rem 0 0;
      font-size: 1.1rem;
      letter-spacing: .5px;
    }
  }
}

div.filter-wrapper {
  @include clearfix();
  margin-bottom: 1rem;

  div.filter-options {
    @include clearfix();
  }

  div.filter-options-item {
    float: left;
    border: 1px solid #e3e3e3;
    //margin-bottom: 1%;

    &.filter-option--multiLevelList {
      @include clearfix();
      
      background: $gainsboro;
      padding: 8px 8px;

      div.filter-option-element {
        margin-bottom: 4px;
      }
    }

    div.filter-option-element {
      &.filter-option-element--bool,
      &.filter-option-element--span {
        position: relative;        

        div.filter-option-box-button {
          //border: 1px solid $gainsboro;
          border-radius: 0;
          cursor: pointer;
          margin: 1px;
          min-height: 14px;
          padding: 5px 8px 6px;
          position: relative;

          border-radius: 0;
          cursor: pointer;
          margin: 1px;
          padding: 7px 8px;

          &:after {
            content: '\f0d7';
            font-family: 'FontAwesome';
            position: absolute;
            right: 10px;
            top: 3px;
            font-size: 1.125rem;
          }

          &:hover {
            background: $ghost;
            //border-width: 2px;
            //margin: 0;
          }
        }

        div.filter-option-box-content {
          background: $white;
          border: 1px solid $gainsboro;
          display: none;
          left: 1px;
          position: absolute;
          right: 1px;
          top: 95%;
          z-index: 20;

          border-top: 0;
          top: 38px;
          left: 0;
          right: 0;
          width: 100%;

          div.filter-option-box-content-input {
            padding: 12px;

            input {
            }

            label {
              display: block;
              float: right;
              width: 90%;
            }
          }

          .button {
          	float: left;
          	width: 50%;
            //font-family: $brandon-text-bold;

            @media #{$medium-down} {
              padding: 12px;
            }

            &.btnClose {
              background: #505050;

              &:hover {
                background: darken(#505050, 5%);
              }
            }

            &.btnOk {
              background: $js-buy-trail-color;

              &:hover {
                background: darken($js-buy-trail-color, 5%);
              }
            }
            &.btnCancel {
              background: #505050;
              &:hover {
                background: darken(#505050, 5%);
              }
            }
          }
        }

        &.open {
          div.filter-option-box-button {
            cursor: inherit;

            &:after {
              content: '\f0d8';
            }
          }

          div.filter-option-box-content {
            display: block;
          }
        }
      }

      &.filter-option-element--span {
        div.filter-option-box-content {
          div.filter-option-box-content-input {
            > div {
              padding: 0;
              margin: 3rem 2rem 1rem;

              .noUi-connect {
                background: $primary-color;
              }
            }
          }
        }
      }
    }
  }

  // Select boxes
  div.SumoSelect {
    width: 100%;

    &:hover {
      p.CaptionCont {
        box-shadow: none;
      }
    }

    p.CaptionCont {
      //border: 1px solid #e3e3e3;
      border: 0;
      border-radius: 0;
      cursor: pointer;
      margin: 1px;

      &:hover {
        background: $ghost;
        //border-width: 2px;
        //margin: 0;
      }

      span.placeholder {
        color: $body-font-color;
        cursor: pointer;
        font-style: normal;
      }
    }
    div.optWrapper,
    div.optWrapper.multiple {
      ul {
        li {
          margin: 0;
          width: 100%;

          &.opt.selected {
            span {
              i {
                background-color: $primary-color;
              }
            }
          }
        }
      }

      div.MultiControls {
        p {
          @extend .button;
          display: inline-block;
          border: none;
          font-size: .9rem;
          letter-spacing: 0.5px;
          padding: .7rem;
          //font-family: $brandon-text-bold;

          &.btnOk {
          	background: $js-buy-trail-color;

          	&:hover {
          		background: darken($js-buy-trail-color, 5%);
          	}
          }
          &.btnCancel {
            background: #505050;
            &:hover {
              background: darken(#505050, 5%);
            }
          }
          @media #{$medium-down} {
            padding: 12px;
          }
        }
      }
    }
  }

  div.filter-choices {
    @include clearfix();

    h3 {
      display: none;
    }

    span.filter-choices-item {
      background: $gainsboro;
      cursor: pointer;
      display: block;
      float: left;
      font-size: 0.75rem;
      padding: 4px 20px 4px 6px;
      margin: 2px;
      position: relative;

      div.filter-choices-multilevel-selected {
        display: inline;
        > span:not(:last-child):after {
          content: "\f105";
          font-family: FontAwesome;
          margin: 0 4px 0 8px;
        }
      }


      &:hover {
        background: darken($gainsboro, 5%);
      }

      span.filter-choices-item--remove {
        position: absolute;
        right: 6px;
        top: 4px;
      }

      &.filter-choices-item--reset {
        background: $alert-color;
        border-color: darken($alert-color, 5%);
        //color: $white;
        cursor: pointer;
        font-weight: bold;
        position: relative;
        float: right;

        &:hover {
          background: darken($alert-color, 5%);
        }

        span {
          font-weight: normal;
          position: absolute;
          right: 6px;
          top: 4px;
        }
      }
    }
  }
}

@media #{$medium-down} {
  div.filter-wrapper-multilevel,
  div.filter-wrapper {

    button.action--show-filter {
      display: block;
      width: 100%;
    }

    div.filter-container {
      //background: $white;
      bottom: 0;
      display: none;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 2000;
      padding: 10px;
      padding: 0;

      div.filter-actions {
        bottom: 10px;
        left: 10px;
        position: absolute;
        right: 10px;

        button.button {
          display: block;
          float: left;
          width: 49%;

          &.action--apply-filter {
            background: $js-buy-trail-color;
            
            &:hover {
              background: darken($js-buy-trail-color, 5%);
            }
          }

          &:last-child {
            float: right;
          }
        }
      }
    }

    &.open {
      button.action--show-filter {
        display: none;
      }

      div.filter-container {
        display: block;
        padding: 20px;
      }
    }

    div.filter-options-item {
      float: left;
      margin-bottom: 1%;

      .SumoSelect  {
        .MultiLevelList > p {
          width: 100%;
        }

        > .optWrapper.isFloating {
          height: 100% !important;
          padding-bottom: 53px;
          max-height: 100%;
          width: 100%;

          > .options {
            box-shadow: 0 0 100px $gainsboro;
          }
        }
      }
    }
  }
}


div.product-list-wrapper {
  position:relative;
  display: block;
  margin: 0 auto;
  width:100%;

  /* For debugging */
  border: 1px solid red;
}

button.loadMore {
  text-align: center;
  padding: 8px 20px;
  margin: 0 auto;
  display: block;
  margin-top: 25px;
  width: 200px;
  background-color: #efefef;
  border: 1px solid #d6d5d5;
}

.ajax-product-loader {
  width: 100%;
  text-align: center;
  height: 0px;
  padding: 20px 0;
  font-size: 20px;
  .fa-spinner {
    position: relative;
    top: -20px;
  }
}

.dynamic-filter-product-actions button.action--dynamicFilter-fetch-products {
  padding: 1rem;
  margin: auto;
  margin-top: 1rem;
}

.show-for-medium-down.button.action--show-filter {
  padding: 1rem;
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(1, 1, 'div.filter-wrapper div.filter-options-item');
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(2, 1, 'div.filter-wrapper div.filter-options-item');
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(5, 1, 'div.filter-wrapper div.filter-options-item');
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(5, 1, 'div.filter-wrapper div.filter-options-item');
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(5, 1, 'div.filter-wrapper div.filter-options-item');
  @include calculate-item-width(5, 1, 'div.filter-wrapper-multilevel div.filter-options-item');
}

}