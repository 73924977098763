/* PRICES
----------------------------------------------------------------------------- */
.product-wrapper .product-price,
.page-listproductswithimageadvanced .product-advanced-price-box span,
.FrontRestProducts .purchase-block-price,
.page-showproduct .purchase-block-price,
.RelatedProductPrice,
.offers-list-wrapper .product-price,
.SearchProductPrice {
  font-weight: bold;
  text-align: center;
  text-transform: none;
}

.purchase-block-price-amount {
  display: inline;
}

.purchase-block-price-amount {
  display: inline-block;
}

.page-showproduct .purchase-block-price-amount {
  font-size: $js-product-page-price-font-size;
}

.page-showproduct .purchase-block-price-previous {
  font-size: $js-product-page-previous-price-font-size;
}

.simple-product-list-wrapper .simple-product-list-item-price {
  font-size: 0.85em;
  font-weight: bold;
}

.page-showproduct .product-discount-in-percentage {
  font-size: 1.3em;
  color: $js-price-discount-color;
  font-weight: bold;
}

.product-wrapper .price-promotion,
.page-listproductswithimageadvanced .price-promotion,
.FrontRestProducts .price-promotion,
.page-showproduct .price-promotion,
.offers-list-wrapper .price-promotion,
.SearchPricePromotion,
.simple-product-list-item-price .price-promotion span {
  color: $js-price-discount-color;
  display: inline-block;
}

.FrontRestProducts .price-previous,
.page-showproduct .purchase-block-price-previous {
  text-decoration: line-through;
  margin: 0 0 0 20px;
}

.product-wrapper .price-previous,
.page-listproductswithimageadvanced .product-advanced-price-previous,
.simple-product-list-item .price-earlier,
.SearchPricePrevious,
.startpage-items .product-price-previous {
  text-decoration: line-through;
  display: inline-block;
  padding-left: 10px;
}

.checkout-text {
  text-align: center;
}

.mypages-text {
  text-align: center;
}

.login-text {
  text-align: center;
}
