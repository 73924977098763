// VARIABLES
$packup-separator-color: #ccc;
$packup-separator-border: 1px solid $packup-separator-color;
$packup-error-color: $alert-color;
$packup-ok-color: #090;
$packup-upsell-activator-inactive-color: #cd3c3c;
$packup-upsell-activator-active-color: #378b2c;

$js-button-disabled-color: #ddd;
$js-button-disabled-background-color: #888888;
$js-button-disabled-border-color: $js-button-disabled-background-color;
$js-button-disabled-opacity: 0.4;

@mixin js-button-global-disabled-style { color: $js-button-disabled-color; background-color: $js-button-disabled-background-color; border-color: $js-button-disabled-border-color; opacity: $js-button-disabled-opacity; cursor: default; }

html.package-active,
html.upsell-active {
  .fancybox-wrap .fancybox-skin {
    background: #ffffff;
  }
}

html.package-active {

  .product-description {
    .inner,
    .product-article-number,
    .product-comments,
    .CategoryLinkWrapper,
    .product-attributes,
    .purchase-block {
      display: none;
    }
  }

  .package-upsell-wrapper .total-price.upper {
    display: block;
    padding-bottom: 0;
  }

}

html.upsell-active {

  .purchase-block-quantity,
  .purchase-block-buy,
  .product-discount-in-percentage {
    opacity: 0.2;
    display: none;
  }

}

.package-upsell-wrapper {

  .package-products:empty,
  .upsell-products:empty {
    display: none;
  }

  .value {
    transition: opacity 0.3s;

    &.changing {
      opacity: 0;
      transition: opacity 0.1s;
    }
  }

  .list-title {
    font-size: 1em;
    padding: 8px;
    border-bottom: $packup-separator-border;
    margin: 20px 0 0;
    background: $accent;
    color: white;

    .package-active & {
      margin-top: 30px;
    }
  }

  // PRODUCT ITEMS
  ul {
    font-size: 0;
  }
  li.package-item,
  li.upsell-item {
    font-size: 14px;
    width: 100%;
    padding: 10px 0;
    border-bottom: $packup-separator-border;
    @include clearfix();

    .image {
      float: left;
      width: 20%;
      position: relative;

      a {
        display: block;
        padding-top: $js-product-wrapper-image-wrapper-height;
        position: relative;
        img {
          position: absolute;
          margin: auto;
          max-width: 100%;
          max-height: 100%;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }

      .spinner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background: #fff;
        opacity: 0;
        transition: opacity 0.1s;
        color: $js-body-font-color;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -10px 0 0 -10px;
          font-size: 20px;
        }
      }

      &.loading {
        .spinner {
          opacity: 1;
        }
      }
    }

    .description {
      float: left;
      width: 80%;
      padding-left: 20px;

      .name {

        h3 {
          font-size: 15px;
          font-weight: bold;
          display: inline-block;
          width: 100%;
          margin: 0;
          line-height: 1.3;

        }

        .item-quantity {
          display: inline-block;
          color: $body-font-color;

          i {
            font-weight: normal;
            font-size: 10px;
            vertical-align: middle;
            margin: 0 0 2px 3px;
          }

          &.item-quantity-1 {
            display: none;
          }

        }

        .subname {
          color: #666;
          margin-bottom: 5px;
          display: block;
          font-style: italic;
          line-height: 1.3;
        }
      }
      .not-buyable-msg {
        margin-top: 20px;
        display: block;
        color: $packup-error-color;
      }

      .product-price {
        font-size: 14px;

        .label {
          display: inline-block;
          font-size: inherit;
          margin-right: 4px;
        }

        .value:last-child {
          padding-left: 6px;
        }

        .original-price {
          display: none;

          span.value {

            + span.value {
              text-decoration: line-through;
              font-size: 0.9em;
            }

            &:empty {
              display: none;

              + span.value {
                text-decoration: line-through;
              }
            }
          }
        }

        .current-price {
          font-weight: bold;
        }

        &.has-discount {
          .current-price {

            .pkg-price,
            .unit {
              color: $js-price-discount-color;
            }

            .out-price {
              font-weight: normal;
              text-decoration: line-through;
              font-size: 0.9em;
            }
          }
        }

        &.no-discount {
          .original-price {
            display: none !important;
          }

          .out-price {
            display: none;
          }
        }
      }

      .stock-status {
        margin-bottom: 5px;
        font-size: 13px;

        .label {
          display: inline-block;
          font-size: inherit;
        }
        .stock-status-value {
          font-weight: bold;
        }
      }

      .attributes-wrapper {
        font-size: 13px;

        .attr-name {
          display: inline-block;
          vertical-align: middle;
          min-width: 80px;
          margin-right: 10px;
          margin-bottom: 0;
          font-weight: bold;

          &::after {
            content: ":";
          }
        }

        .attributes-lv2 {
          padding: 5px 0 0;
        }

        .attribute-select-wrapper {
          display: inline-block;
          vertical-align: middle;
          position: relative;

          &::after {
            content: "";
            font-family: FontAwesome;
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
            font-weight: normal;
            line-height: 1;
          }

          &.disabled {
            opacity: 0.5;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1;
            }
          }

          &.error {
            &::after {
              color: $packup-error-color;
              content: "\f06a";
            }
          }

          &.valid {
            &::after {
              color: $packup-ok-color;
              content: "\f05d";
            }
          }

          &.attr-lv2 {
            display: none;

            &.visible {
              display: inline-block;
            }
          }

          select {
            min-width: 100px;
            display: inline-block;
            vertical-align: middle;

            option {

              &.disabled {
                color: #aaa;
              }
            }
          }
        }
      }
    }

    &.not-buyable {

      .product-price {
        display: none !important;
      }
    }

    &.upsell-item {
      position: relative;

      .description {
        padding-right: 60px;
      }
      .activate-purchase {
        //float: left;
        //width: 50px;

        .activator {
          position: absolute;
          top: 50%;
          right: 0;
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          width: 50px;
          height: 28px;
          line-height: 28px;
          margin-top: -14px;
          font-size: 14px;

          input {
            display: none;

            &:checked + label {
              background: $packup-upsell-activator-active-color;
              text-indent: 9px;

              &::before {
                left: 35px;
              }

              &::after {
                content: '\f00c';
                color: #fff;
              }
            }
          }

          label {
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            cursor: pointer;
            background: $packup-upsell-activator-inactive-color;
            border-radius: 4px;
            font-weight: bold;
            color: #FFF;
            transition: background 0.3s, text-indent 0.3s;
            text-indent: 24px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4) inset;

            &::before {
              content: '';
              width: 12px;
              height: 22px;
              border-radius: 2px;
              background: #FFF;
              position: absolute;
              z-index: 2;
              top: 3px;
              left: 3px;
              display: block;
              -webkit-transition: left 0.3s;
              -moz-transition: left 0.3s;
              -o-transition: left 0.3s;
              transition: left 0.3s;
              -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
              -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
            }

            &::after {
              content: '\f05e';
              font-family: FontAwesome;
              font-size: 20px;
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              color: #fff;
              z-index: 1;
            }
          }
        }
      }

      &.buy-inactive {
        .attribute-select-wrapper::after {
          display: none !important;
        }
      }

      &.not-buyable {
        .activate-purchase {
          display: none !important;
        }
        .description {
          padding-right: 0;
        }
      }
    }

    &[data-quantity='1'] .unit {
      display: none !important;
    }
  }

  &.verbose-pricing {
    .description .product-price {
      .original-price {
        display: block !important;
      }
      .current-price {
        .out-price {
          display: none;
        }
      }
    }
  }

  &.hide-subname .subname {
    display: none !important;
  }

  &.hide-stock-status .stock-status {
    display: none !important;
  }

  &.hide-item-price .product-price {
    display: none !important;
  }

  &.hide-item-qty .item-quantity {
    display: none !important;
  }

  // PRICE SUMMARIES
  .total-price {
    padding: 15px 0;
    font-size: 16px;

    &.upper {
      display: none;
    }

    .original-price {
      display: none;
      transition: 0.2s;

      span.value {

        + span.value {
          text-decoration: line-through;
          font-size: 0.9em;
          padding-left: 5px;
        }

        &:empty {
          display: none;

          + span.value {
            text-decoration: line-through;
            font-size: 1em;
            padding-left: 0px;

          }
        }
      }
    }

    .current-price {
      font-weight: bold;
      font-size: 20px;
      max-height: 40px;

      .out-price {
        transition: 0.2s;
        font-weight: normal;
        text-decoration: line-through;
        font-size: 0.9em;
        padding-left: 5px;

      }
    }

    .label {
      display: inline-block;
      font-size: inherit;
    }

    .savings {
      transition: 0.2s;
      padding: 5px 0 0;
      max-height: 40px;
    }

    &.has-discount {
      .current-price {

        .pkg-price {
          color: $js-price-discount-color;
        }

        .label.total {
          display: none;
        }

      }
    }

    &.no-discount {
      .original-price,
      .savings,
      .current-price .out-price {
        //display: none !important;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
      }

      .current-price .label.package {
        display: none !important;
      }
    }

  }

  &.verbose-pricing {
    .total-price {
      .original-price {
        display: block;
      }
      .current-price {
        .out-price {
          display: none;
        }
      }
    }
  }

  &.hide-savings {
    .savings {
      display: none !important;
    }
  }

  //
  // ADD TO CART-BLOCK
  //
  .add-to-cart-wrapper {
    display: block;
    position: relative;
    text-align: center;
    width: 100%;

    .error-messages {

      p {
        display: none;
        border: 2px solid $packup-error-color;
        padding: 5px 10px;
        color: $packup-error-color;

        &.active {
          display: block;
        }
      }
    }

    .qty {
      display: none;
      width: 44px;
      height: 44px;
      padding: 0;
      text-align: center;
      border: 1px solid $primary-color;
      font-size: 16px;
      margin-right: 10px;
      margin-bottom: 10px;
      &.disabled & {
        border: 3px solid #DDD;
      }
    }
    .buy-button {
      display: block;
      font-size: 16px;
      padding: 10px 20px;
      background: $js-buy-trail-color;
      &:hover {
        background: darken($js-buy-trail-color, 10%);
      }

    }

    &.disabled {

      .buy-button {
        @include js-button-global-disabled-style();
      }
    }
  }
}

//
// ITEM INFO POPUP
//

#popup-container {

  .spinner-container {
    line-height: 100px;
    text-align: center;
    font-size: 40px;
  }

}

.package-upsell-item-popup {
  max-width: 800px;
  min-width: 500px;
  background: white;
  @include clearfix();

  .product-wrapper {

    .image {
      float: left;
      width: 40%;
      padding: 10px 20px;
      text-align: center;

      img {
        margin: auto;
        max-width: 100%;
        max-height: 400px;
      }
    }

    .product-info {
      float: right;
      width: 60%;
      .name {
        margin-bottom: 14px;
        h3 {
          margin-bottom: 0;
        }
        .subname {
          font-size: 16px;
          font-weight: bold;
          color: $js-product-list-subname-color;
        }
      }

      .short-description {
        font-style: italic;
        margin-bottom: 10px;
      }
    }
    .product-page-link {
      padding: 20px 0;
      a {
        color: $js-primary-link-color;
        font-size: 16px;

        &:hover {
          color: $js-primary-link-hover-color;
        }

        &::after {
          font-family: FontAwesome;
          content: "\f105";
          margin: 0 0 0 10px;
        }
      }
    }
  }
}

// OVERLAY
#packup-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  opacity: 0;
  transition: 0.2s;
  background: rgba(255, 255, 255, 0.8);

  i {
    position: fixed;
    font-size: 30px;
    top: 50%;
    left: 50%;
    margin: -0.5em 0 0 -0.5em;
    opacity: 0;
    transition: 0.2s 0.2s;
    display: block;
    line-height: 1;
  }

  &.hidden {
    height: 0;
    top: initial;

    i {
      display: none;
    }
  }

  &.active {
    opacity: 1;

    i {
      opacity: 1;
    }
  }
}

//
//    MEDIUM DOWN
//
@media #{$medium-down} {

  //Product Popup.
  .package-upsell-item-popup {
    min-width: 250px;
    max-width: 400px;

    .product-wrapper {
      .image {
        float: none;
        width: 100%;
      }
      .product-info {
        float: none;
        width: 100%;
      }
    }
  }
}

//
//    SMALL ONLY
//
@media #{$small-only} {

}