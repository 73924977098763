//
//  Start Page
//

.page-default {
  #path-nav {
    display: none;
  }
}

.startpage-header {
  display: none;
}

.startpage-image {
  display: none;
}

.page-default .startpage-image {
  text-align: center;
  margin-bottom: 12px;
}

.page-default .startpage-text {
  margin-bottom: 4px;
}

//
//  Hide the div that holds the HTML-definitions for sliders
//
#switcher-definitions {
  display: none;
}


#startpage_list {
  ul {
    margin-top: 20px;
    margin-bottom: 20px;
    &.image-wrapper {
      @media #{$medium-down} {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    li {
      margin-bottom: 0 !important;
      position: relative;
      .custom-item-text {
        h2 {
          text-align: center;
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 15px;
          margin-top: 10px;
        }
      }
      &.image {
        @media #{$small-only} {
          width: 100% !important;
          margin-right: 0 !important;
          margin-bottom: 10px !important;
        }
        @media #{$medium-only} {
          margin-bottom: 10px !important;
        }
        .custom-item-text {
          float: left;
          //position: absolute;
          //width: 100%;
          left: 0;
          //right: 0;
          background: $primary-color;
          //bottom: 0;
          text-align: center;
          padding:10px;

          //position: absolute;
          //background: rgba(3, 46, 137, 0.85);
          //max-width: 70%;
          width: 100%;
          margin: 0 auto;
          background: rgba(0,0,0,0.25);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @media #{$medium-down} {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            transform: none;
            padding: 10px;
          }
          h2 {
            font-weight: normal;
            width: 100%;
            color: #fff;
            border-bottom: 0;
            padding-bottom: 0;
            margin-top: 0 ;
          }
          p {
            color: #fff;
            padding-left: 40px;
            padding-right: 40px;
          }
          a {
            color: #fff;
            padding: 10px;
            border: 1px solid #fff;
            text-transform: uppercase;
            display: block;
            max-width: 40%;
            margin: 0 auto;
            margin-top: 20px;
            &:hover {
              background: #333;
            }
          }
        }
        img {
          width: 100%;
        }
      }
      .product-info {
        text-transform: uppercase;
        @include product-name-h3
      }
      .product-wrapper {
        .product-image {
          padding-top: $js-startpage-wrapper-image-wrapper-height;
        }
      }
    }
  }
}


// IMAGE PUSH
#startpage_list ul li .image-push {
  position: relative;
  .custom-item-text {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background: $primary-color;
    bottom: 0;
    @media #{$small-only} {
      position: relative;
      width: 100%;
      display: block;
    }
    h2 {
      color: #fff;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      border-bottom: 0;
      padding-top: 18px;
      line-height: 17px;
      padding-bottom: 20px;
      font-size: 1.3rem;
      @media #{$small-only} {
        font-size: 1rem;
      }
    }
  }
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 2)
  }
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 4)
  }
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
  #startpage_list .items-2 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 1.4);
  }
}

