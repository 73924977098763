//
//  Header
//

#header {
  clear: both;
  position: relative;
  width: 100%;
  margin: 0;
  //background-color: $js-header-background-color;
  @include clearfix();
  background: url(/M1/stage/images/responsive-base/body-bg3.jpg) no-repeat;
  background-position: 0px;
  background-size: cover;
  #TEH_LOGOTYPE {
    position: relative;
    float: left;
    display: inline-block;
    margin-top: 15px !important;
    padding-left: 30px;
    @media #{$medium-down} {
      display: none;
    }
  }
}

#header-inner {
  max-width: $js-max-page-width;
  margin: 0 auto;
  @include clearfix();
  height: 130px;
  @media #{$medium-down} {
    height: auto;
  }
}

.top-bar-content-wrapper {
  float: left;
  line-height: 16px;
  padding-top: 5px;
  a {
    padding-right: 20px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
}


.info-wrapper {
  float: right;
  margin-top: 15px;
  p {
    margin-bottom: 0;
    padding-right: 20px;
    display: inline-block;
    font-size: 13px;
  }
  em {
    font-size: 18px;
  }
}

.logo-wrapper {
  float: left;
  margin: $js-logo-wrapper-margin;

  .logo-header {
    display: none;
  }

  .logo-body {
    max-width: $js-logo-wrapper-max-width;
    line-height: 0;
    &:hover {
      cursor: pointer;
    }
  }

  input {
    outline: none;
    max-width: 100%;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
  }

  .logo-footer {
    display: none;
  }
}

.header-scrolled .logo-wrapper {
}

//
//  Sub Navigation / Breadcrumbs
//
#path-nav {
  display: block;
  left: 0;
  width: 100%;
  max-width: $js-max-page-width;
  padding-left: 10px;
  padding-right: $js-page-padding-right;
  padding-top: 10px;
  padding-bottom: 2px;
  font-size: $js-breadcrumb-font-size;
  margin: 0 auto;
  display: none;

  .breadcrumb-link {
    color: $js-breadcrumb-font-color;
    display: inline-block;
  }

  .breadcrumb-spacer {
    color: $js-primary-link-color;
  }
}

//
//  Search Box
//

.search-box-wrapper {
  font-size: 14px;
  //padding: $js-search-box-wrapper-padding;
  //margin: $js-search-box-wrapper-margin;
  margin-top: -7px;
  float: right;
  padding: 5px 0 5px 5px;
  background-color: $js-search-box-wrapper-background-color;
}

.search-box-header {
  display: none;
}

.search-box-body {
  position: relative;
  overflow: hidden;
  height: auto;
  line-height: 32px;
}

.search-box-input {
  float: left;
}

.search-box-input input {
  border: 1px solid #4c6fb9;
  width: $js-search-box-input-width;
  background-color: $js-search-box-input-background-color;
  padding: $js-search-box-input-padding;
  font-size: $js-search-box-input-font-size;
  outline: none;
  color: $js-search-box-input-color;
  @include border-radius(0);
  -webkit-appearance: none;
  display: block;
}

.search-box-input input:focus {
  background: $js-search-box-input-background-color-focus;
  color: $js-body-font-color;
}

.search-box-button {
  float: left;
  margin-top: -2px;
  position: absolute;
  right: 6px;
  a {
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  img {
    width: 21px;
  }
}

/* MARQUEE
----------------------------------------------------------------------------- */
.marquee {
  margin: 0;
  display: none;
}

//
//    MEDIUM DOWN
//
@media #{$medium-down} {
  .vat-selector-wrapper .vat-selector-label span {
    font-size: 1em;
  }
  .logo-wrapper {
    //margin: $js-logo-wrapper-margin-medium;
    margin: 20px auto;
    float: none;
    text-align: center;
    .logo-body {
      max-width: 100%;
      input {
        max-width: 250px;
      }
    }
  }
}


//
//  Small
//
@media #{$small-only} {
  .logo-wrapper {
    float: none;
    display: inline-block;
    width: 100%;
    margin: $js-logo-wrapper-margin-small;
    .logo-body {
      margin: 0 auto;
    }
  }
}